import { useEffect, useState } from "react";

import LoadingSpinner from "components/common/LoadingSpinner";

import useAAMWStore from "store/useAAMWStore";

import { sleep } from "utils/helper";

const Secret = () => {
  const [secretCode, setSecretCode] = useState("");
  const [stageText, setStageText] = useState("Please enter the secret code");

  const hasCorrectSecretCode = secretCode == process.env.REACT_APP_SITE_SECRET;

  const { updateIsUserValidated } = useAAMWStore();

  const mockLoading = async () => {
    setStageText("Taking a look..");
    await sleep(2000);
    setStageText("Almost there...");
    await sleep(2000);
    setStageText("You're in!");
    await sleep(2000);
    localStorage.setItem("aamv", new Date().toISOString());
    updateIsUserValidated(true);
  };

  useEffect(() => {
    if (hasCorrectSecretCode) {
      mockLoading();
    }
  }, [secretCode]);

  return (
    <div
      className="position-absolute w-100 h-100 d-flex justify-content-center"
      style={{
        backgroundColor: "rgba(51,50,49,0.5)"
      }}
    >
      <div className="d-flex px-3 flex-column align-items-center justify-content-center">
        <h1
          className="fancy-text mb-4 fw-bolder text-white brand-text"
          style={{
            fontSize: "50px",
            marginTop: "-120px"
          }}
        >
          {stageText}
        </h1>
        {hasCorrectSecretCode ? (
          <LoadingSpinner color="white" />
        ) : (
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-group">
              <input
                type="text"
                className="fancy-input-field"
                id="secret-code"
                placeholder="secret code"
                required
                value={secretCode}
                onChange={(e) => setSecretCode(e.target.value)}
              />
              <label htmlFor="secret-code">secret code</label>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Secret;
