import { Fragment } from "react/jsx-runtime";

import { WEDDING_COLORS } from "constants/colors";

import RingsIcon from "components/icons/RingsIcon";

import useAAMWStore from "store/useAAMWStore";

import Home from "./Home";
import Secret from "./SecretSection";

import ceremonyPhoto from "assets/img/ceremony.jpg";
import coverPhoto from "assets/img/photo-3.jpg";
import receptionPhoto from "assets/img/receptionPhoto.jpg";

const Landing = () => {
  const { isUserValidated } = useAAMWStore();

  return (
    <Fragment>
      <section
        className="position-relative section-cover vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url('${coverPhoto}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed"
        }}
      >
        {!isUserValidated ? <Secret /> : <Home />}
      </section>
      {isUserValidated && (
        <Fragment>
          <section className="rings-section px-5 bg-brand-primary text-white brand-text">
            <div className="left-content">
              <h3
                style={{
                  fontSize: 50
                }}
              >
                The Wedding Day
              </h3>
              <p className="fs-4 mb-0">Thursday, April 24 2025, 3 p.m.</p>
            </div>
            <div className="rings-container">
              <RingsIcon />
            </div>
            <div className="right-content">
              <h4 className="fs-2">Where to stay</h4>
              <span className="fs-5">
                We recommend a BNB near Forest Hill, which is where we live.
                Then car/uber from there
              </span>
            </div>
          </section>

          <section className="section-reception d-flex bg-brand-secondary">
            <div className="reception-container text-normal">
              <div className="img-container">
                <img src={ceremonyPhoto} alt="Ceremony" />
                <div className="img-texts brand-text px-4">
                  <h2 className="brand-text">Ceremony</h2>
                  <p>
                    Thursday April 24 2025, 3 p.m. St. Patrick's Church 67
                    Clarke St Lilydale VIC 3140
                  </p>
                </div>
              </div>
              <div className="img-container">
                <img src={receptionPhoto} alt="" />
                <div className="img-texts brand-text px-4">
                  <h2 className="brand-text">Reception</h2>
                  <p>
                    Thursday April 24 2025, 5.30 p.m. The Little Vineyard 17
                    Paynes Rd, Chirnside Park VIC 3116
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="section-colors d-flex bg-brand-secondary justify-content-center">
            <div className="section-colors-container">
              <h2 className="brand-text text-normal">
                Motif for guests is autumn colors
              </h2>
              <div className="colors-container">
                {WEDDING_COLORS.map((color) => (
                  <div
                    key={color}
                    className="wedding-color-circle"
                    style={{ backgroundColor: color }}
                  />
                ))}
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Landing;
