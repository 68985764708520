import { Fragment } from "react/jsx-runtime";

import pic1 from "assets/img/our-story/our-story-pic-1.jpg";
import pic2 from "assets/img/our-story/our-story-pic-2.jpg";
import pic3 from "assets/img/our-story/our-story-pic-3.jpg";

const OurStory = () => {
  return (
    <Fragment>
      <section className="our-story-section-1 bg-brand-primary text-white brand-text">
        <h3>God made everything beautiful in its time.</h3>
      </section>
      <section className="our-story-section mx-0 vw-100 bg-brand-secondary">
        <div className="container py-5 d-flex justify-content-between align-items-center">
          <div className="img-container d-flex left-image">
            <img
              src={pic1}
              alt=""
              style={{
                height: "60vh"
              }}
            />
          </div>
          <div className="text-container">
            <p className="brand-text">
              It all began during the COVID pandemic. It was the 10th of April
              2021 in Auckland, New Zealand. Lured by the promise of free food,
              Miguel attended a course organised by Singles for Christ (SFC).
              Ann on the other hand, was on her third attempt to finish the same
              SFC program.
            </p>
            <p className="brand-text">
              As with most Filipino gatherings, the event started on “Filo
              time”, giving Ann and Miguel, who were seated next to each other,
              plenty of time to chat. He made her laugh; she made him smile. He
              thought she had a wonderful personality, an accountant like his
              mom. She thought he was kind, and spoke highly of his mother.
            </p>
          </div>
        </div>
      </section>
      <section className="our-story-section mx-0 vw-100 bg-brand-primary">
        <div className="container py-5 d-flex justify-content-between align-items-center">
          <div className="text-container">
            <p className="brand-text text-white">
              By the third week, Miguel asked Ann on a date. He chose a
              Vietnamese restaurant in Parnell, Auckland, and confidently
              ordered a classic vermicelli salad with pork spring rolls,
              thinking he had made the better choice. He soon realised he was
              wrong. As he ate, he was jealous of Ann’s dish – a braised pork in
              claypot which looked more flavourful.
            </p>
            <p className="brand-text text-white">
              Fast forward to numerous dates, another lockdown happened, but
              this did not stop these two from secretly meeting in local parks
              for walks together.
            </p>
          </div>
          <div className="img-container d-flex right-image">
            <img
              src={pic2}
              alt=""
              style={{
                height: "60vh"
              }}
            />
          </div>
        </div>
      </section>
      <section className="our-story-section mx-0 vw-100 bg-brand-secondary">
        <div className="container py-5 d-flex flex-column justify-content-between align-items-center">
          <div className="text-container">
            <p className="brand-text">
              Despite the challenges of starting a relationship during the
              pandemic, moving to Melbourne to build their lives together,
              saving for a house deposit, and changing jobs, they knew it was
              only a matter of time before Miguel would pop the question – “Will
              you marry me?”
            </p>
            <p className="brand-text">
              So, on the 10th of November 2024, realising that Ann hasn’t had
              any coffee yet, Miguel saw his chance. He took her to Blackburn
              Lake Sanctuary for a “coffee” and a walk. Overlooking the lake, he
              got down on one knee and proposed. She said YES. And he also
              bought her coffee as he promised 😊
            </p>
          </div>
          <div className="img-container d-flex bottom-image">
            <img src={pic3} alt="" />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default OurStory;
