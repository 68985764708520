import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";

const Home = () => {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 100);
  }, []);

  return (
    <div
      className="position-absolute w-100 h-100 d-flex justify-content-center"
      style={{
        backgroundColor: "rgba(51,50,49,0.5)"
      }}
    >
      <div
        className="d-flex px-3 flex-column align-items-center justify-content-center"
        style={{
          marginTop: 200
        }}
      >
        <h1 className="home-title brand-text text-brand-secondary text-center">
          <span
            className={classNames("fade-text-1", {
              isMounted
            })}
          >
            Ann
          </span>
          <span
            className={classNames("fade-text-2", {
              isMounted
            })}
          >
            &{" "}
          </span>
          <span
            className={classNames("fade-text-3", {
              isMounted
            })}
          >
            Miguel
          </span>
          <p
            className={classNames("subtitle mt-4", {
              isMounted
            })}
          >
            Please RSVP by 31 January!
          </p>
        </h1>
        <div
          className={classNames(
            "home-badge brand-text text-normal d-flex align-items-center bg-brand-secondary",
            {
              isMounted
            }
          )}
          style={{
            marginTop: 80
          }}
        >
          <div
            className="d-flex flex-column"
            style={{
              textAlign: "right"
            }}
          >
            <span>April 24, 2025</span>
            <span>Thursday, 3 p.m</span>
          </div>
          <div
            className="divider bg-black"
            style={{
              height: "55%",
              width: 1
            }}
          ></div>
          <div className="d-flex flex-column">
            <span>St. Patrick's Church</span>
            <span>Lilydale VIC</span>
          </div>
        </div>

        <button
          className={classNames(
            "our-story-button btn-tertiary brand-text mt-5",
            {
              isMounted
            }
          )}
          onClick={() => navigate("/our-story")}
        >
          <span>Our Story</span>
        </button>
      </div>
    </div>
  );
};

export default Home;
